import { StepSyncProgress, type IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface ISyncProgressJSON {
    resourcesProgress: Array<IStepSyncProgressJSON>;
    markedAsCanceled: boolean;
    currentStepIndex: number;
    dateTimeStart: string;
    dateTimeEnd?: string;
    requirePageReload: boolean;
    customData: { [ key: string ]: any };
}

export interface ISyncProgressOptions {
    markedAsCanceled?: boolean;
    currentStepIndex?: number;
    dateTimeStart?: Date;
    dateTimeEnd?: Date;
    requirePageReload?: boolean;
    customData?: { [ key: string ]: any };
}

export class SyncProgress {
    public resourcesProgress: Array<StepSyncProgress> = new Array<StepSyncProgress>();
    public markedAsCanceled: boolean = false;
    public currentStepIndex: number = 0;
    public dateTimeStart: Date = new Date();
    public dateTimeEnd: Date | undefined;
    public requirePageReload: boolean = false;
    public customData: { [ key: string ]: any } = {};
    public errors = new Array<Error>();

    public get hasError(): boolean {
        return this.errors.length > 0 || this.resourcesProgress.some((resourceProgress, index, array) => {
            return this._hasError(resourceProgress);
        });
    }

    public get complete(): boolean {
        return this.dateTimeEnd instanceof Date;
    }

    public get currentStep(): number {
        return this.currentStepIndex + 1;
    }

    public get currentSyncStepProgress(): StepSyncProgress {
        return this.resourcesProgress[this.currentStepIndex];
    }

    private get totalCount(): number {
        return this.resourcesProgress.reduce((total, stepProgress) => {
            if (stepProgress.hasOwnProperty('stepsProgress') && Array.isArray((stepProgress as any)['stepsProgress'])) {
                return total + (stepProgress as any)['stepsProgress'].length;
            }

            return total + 1;
        }, 0);
    }

    public get currentSyncProgressPending(): number {
        return this.resourcesProgress.reduce((total, { progressStatusPending }) => {
            if (!isNaN(progressStatusPending)) {
                return total + progressStatusPending;
            }
            return total;
        }, 0) / this.totalCount;
    }

    public get currentSyncProgressSuccess(): number {
        return this.resourcesProgress.reduce((total, { progressStatusSuccess }) => {
            if (!isNaN(progressStatusSuccess)) {
                return total + progressStatusSuccess;
            }
            return total;
        }, 0) / this.totalCount;
    }

    public get currentSyncProgressError(): number {
        return this.resourcesProgress.reduce((total, { progressStatusError }) => {
            if (!isNaN(progressStatusError)) {
                return total + progressStatusError;
            }
            return total;
        }, 0) / this.totalCount;
    }

    get progressStatusRecordsCompleted(): number {
        return this.resourcesProgress.reduce((recordsCompleted, resourceProgress) => {
            return recordsCompleted + resourceProgress.progressStatusRecordsCompleted;
        }, 0);
    }

    get progressStatusRecordsToDownload(): number {
        return this.resourcesProgress.reduce((recordsCompleted, resourceProgress) => {
            return recordsCompleted + resourceProgress.progressStatusRecordsToDownload;
        }, 0);
    }

    get progressStatusFilesCompleted(): number {
        return this.resourcesProgress.reduce((recordsCompleted, resourceProgress) => {
            return recordsCompleted + resourceProgress.progressStatusFilesCompleted;
        }, 0);
    }

    get progressStatusFilesToDownload(): number {
        return this.resourcesProgress.reduce((recordsCompleted, resourceProgress) => {
            return recordsCompleted + resourceProgress.progressStatusFilesToDownload;
        }, 0);
    }

    constructor(options: ISyncProgressOptions | ISyncProgressJSON | null = null) {
        options?.markedAsCanceled && (this.markedAsCanceled = options.markedAsCanceled);
        options?.currentStepIndex && (this.currentStepIndex = options.currentStepIndex);
        options?.dateTimeStart && (typeof options.dateTimeStart === 'string') && (this.dateTimeStart = new Date(options.dateTimeStart));
        options?.dateTimeStart && (typeof options.dateTimeStart !== 'string') && (this.dateTimeStart = options.dateTimeStart);
        options?.dateTimeEnd && (typeof options.dateTimeEnd === 'string') && (this.dateTimeEnd = new Date(options.dateTimeEnd));
        options?.dateTimeEnd && (typeof options.dateTimeEnd !== 'string') && (this.dateTimeEnd = options.dateTimeEnd);
        options?.requirePageReload && (this.requirePageReload = options.requirePageReload);
        options?.customData && (this.customData = options.customData);
    }

    private _hasError(resourceSyncProgress: StepSyncProgress): boolean {
        return resourceSyncProgress.hasError;
    }

    public toJSON(): ISyncProgressJSON {
        return Object.assign({}, this, {
            resourcesProgress: this.resourcesProgress.map((resourceProgress) => { return resourceProgress.toJSON() }),
            dateTimeStart: this.dateTimeStart.toISOString(),
            dateTimeEnd: this.dateTimeEnd?.toISOString(),
        });
    }

    public markSyncAsCanceled(): void {
        this.markedAsCanceled = true;
    }
}
